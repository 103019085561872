import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    {/* <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Route exact path="/" component={App} />
    </BrowserRouter> */}
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
