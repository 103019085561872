import "./App.css";
import Header from "./components/Header/Header";
import MainContent from "./components/MainContent/MainContent";
import Footer from "./components/Footer/Footer";
import React from "react";
import LocomotiveScroll from "locomotive-scroll";

const isMobile = window.matchMedia(
  "only screen and (max-width: 760px)"
).matches;
class App extends React.Component {
  componentDidMount() {
    new LocomotiveScroll({
      el: document.querySelector(".App"),
      smooth: true,
      getDirection: true,
      mobile: {
        breakpoint: 0,
        smooth: false,
        getDirection: true,
      },
      tablet: {
        breakpoint: 0,
        smooth: false,
        getDirection: true,
      },
    });
  }

  render() {
    if (isMobile) {
      return (
        <div className="App">
          <div>
            <div>
              <Header />
            </div>

            <div>
              <div id="bg-sq1" />
            </div>
            <div>
              <div id="bg-sq2" />
            </div>

            <div>
              <MainContent />
            </div>

            <Footer />
          </div>
        </div>
      );
    } else {
      return (
        <div className="App">
          <div data-scroll-section>
            <div data-scroll data-scroll-speed="2">
              <Header />
            </div>

            <div data-scroll data-scroll-speed="-2">
              <div id="bg-sq1" />
            </div>
            <div data-scroll data-scroll-speed="-4">
              <div id="bg-sq2" />
            </div>

            <div data-scroll data-scroll-speed="1">
              <MainContent ref={this.ref} />
            </div>

            <Footer />
          </div>
        </div>
      );
    }
  }
}

export default App;
